footer {
    width: 100%;
    background-color: var(--main);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    p {
        text-align: center;
        padding: 10px;
    }

    a:hover {
        color: currentColor;
    }
}