header {
    display: flex;
    align-items: center;
    height: 130px;
    width: 100%;
    padding: 0 50px;
    background-image: linear-gradient(to bottom, #5518ed15, transparent);

    .mobile {
        display: none;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1440px;
        width: 100%;

        a {
            font-size: 20px;
            font-weight: 500;
        }
    }

    @media only screen and (max-width: 1000px) {
        padding: 10px 20px 10px 10px;

        .container {
            display: none;
        }

        .mobile {
            display: flex;
        }
    }
}